<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/layers/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title v-show="(form.lay_id > 0)">Fiche {{form.lay_id}} : {{form.lay_name}}</v-toolbar-title>
        <v-toolbar-title v-show="(form.lay_id == 'ajouter')">Nouvelle fiche</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-4 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.lay_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="cacheType" v-model="form.lay_typ_id" label="Type">
            </v-select>
            <!-- <v-text-field disabled label="Association de champs" v-model="form.typ_entity"></v-text-field> -->
            <v-btn @click="switchFieldAssoc" color="blue" :outlined="(form.lay_id > 0) ? true : false" class="mt-3" :disabled="(form.lay_id > 0) ? false : true">
                {{ (form.lay_id > 0) ? 'Association de champs' : 'Enregistrer pour associer des champs' }}
            </v-btn>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="cacheState" v-model="form.lay_sta_id" label="Statut" single-line >
            </v-select>
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="form.lay_color" block dark class="mt-3" v-on="on">Couleur</v-btn>
                </template>
                <v-color-picker 
                    v-model="form.lay_color" class="ma-2 elevation-0"
                    hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-file-input @change="getFileLayer" ref="layerfile" v-model="form.lay_layerfile" show-size label="Fichier(s)"></v-file-input>
        </v-flex>
        </v-layout>
    </v-card>
    <div class="ma-4 text-right">
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/layers/">Retour</v-btn>
    </div>
    <v-dialog v-model="dialField" max-width="1000" persistent>
        <v-card>
        <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">Associer des champs</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-data-table :headers="layFieldsHeaders" :items="form.lay_field" :items-per-page="5" class="elevation-1" >
            <template v-slot:[`header.id`]="">
                <v-btn @click="addFieldAssoc" x-small dark fab color='success'><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, index) in items" :key="index">
                <td class="text-xs-left">
                    <v-select v-if="sourceProperties.length" 
                        :items="sourceProperties" v-model="item.source" dense class="pt-3" 
                        item-text="text" item-value="value">
                    </v-select>
                    <v-text-field v-else v-model="item.source"></v-text-field>
                </td>
                <td class="text-xs-left">
                    <v-select :items="itemDes" v-model="item.destination" dense class="pt-3" item-text="text" item-value="value"></v-select>
                </td>
                <td class="text-xs-left"><v-switch v-model="item.infoPanel"></v-switch></td>
                <td class="text-xs-left"><v-switch v-model="item.infoPopup"></v-switch></td>
                <td class="text-xs-left"><v-icon style="cursor: pointer;" @click="deleteFieldAssoc(index)">mdi-delete</v-icon></td>
            </tr>
            </tbody>
            </template>
<!--                <template v-slot:item.source="{ item }">
                    <v-text-field v-model="item.source"></v-text-field>
                </template>
                <template v-slot:item.destination="{ item }">
                    <v-select :items="itemDes" v-model="item.destination" dense class="pt-3"
                        item-text="text" item-value="value"></v-select>
                </template>
                <template v-slot:item.infoPanel="{ item }">
                    <v-switch v-model="item.infoPanel"></v-switch>
                </template>
                <template v-slot:item.infoPopup="{ item }">
                    <v-switch v-model="item.infoPopup"></v-switch>
                </template>
-->
            </v-data-table>
        </v-card-text>
        <v-card-actions class="pt-0 mr-3">
            <v-spacer></v-spacer>
            <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="switchFieldAssoc; saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
            <v-btn color="grey" outlined @click.native="switchFieldAssoc">Fermer</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'layer_edit',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            dialField   : false,
            cacheType: cacheGetters.getTypes('SPR') || [],
            cacheState: cacheGetters.getStates('GEN') || [],
            itemDes : [
                { text: 'Aucun', value: '' },
                { text: 'Code mc2', value: 'trs_mc2_code' },
                { text: 'Ident mc2', value: 'trs_mc2_id' },
                { text: 'Coddt',   value: 'trs_coddt' },
                { text: 'Commune insee', value: 'tow_code_insee' }
            ],
            visibility: [
                {'value': 0,    'text': 'Visible'},
                {'value': 1,    'text': 'Désactivé'}
            ],
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            form:{
              lay_id:'', lay_sta_id: 'GEN02', lay_typ_id: 'SPR02',
              lay_name: '', lay_source_filename: '', lay_filename: '', 
              lay_color: '#FF0000', lay_field: [], lay_created_at: '', lay_layerfile: null
            },
            layFieldsHeaders: [
                { text: 'Source',       value:'source',  width: '34' },
                { text: 'Association',  value:'destination',  width: '34' },
                { text: 'Zone info',    value:'infoPanel',  width: '34', sortable: false },
                { text: 'Popup carte',  value:'infoPopup',  width: '34', sortable: false },
                { text: '-',            value:'id',  width: '34', sortable: false }
            ],
            locDateFormat: dateLocale, // Format de date
            sourceProperties: []
        }
    },
    computed: {
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        }
    },
    mounted:function (){
        if( ( this.$route.params.lay_id ) && this.$route.params.lay_id > 0 ){
            this.getLayer()
        } else {
            this.form.lay_id = 'ajouter' //this.$route.params.lay_id
        }
    },
    methods: {
        getLayer(){
            this.$http.get( '/layers/' + this.$route.params.lay_id ).then( (response) => {
                if( response.data.data.lay_field == 'null' ){
                    response.data.data.lay_field = []
                } else {
                    try {
                        response.data.data.lay_field = JSON.parse(response.data.data.lay_field)
                    } catch {
                        response.data.data.lay_field = []
                    }
                }
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
            this.$http.get( '/layers/geojson/' + this.$route.params.lay_id ).then( (response) => {
                if( response.data?.data?.lay_geojson?.features?.[0]?.properties ){
                    let properties = response.data.data.lay_geojson.features[0].properties
                    for( const prop in properties ){
                        this.sourceProperties.push( { text: prop + ' (ex: ' + properties[prop] + ')', value: prop } )
                    }
                }

            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == 'lay_field' ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form.lay_layerfile ) {
                formFile.append('lay_layerfile', this.form.lay_layerfile, this.form.lay_layerfile.name)
            }
            //var config = {
            //    onUploadProgress: (progressEvent) => {
            //        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
            //    }
            //}

            if( this.$route.params.lay_id > 0 && this.$route.params.lay_id !== 'ajouter' ){
                this.$http.post( '/layers/' + this.$route.params.lay_id, formFile ).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/layers' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            } else {
                this.$http.post( '/layers/', formFile ).then( (reponse) => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/layers/' + reponse.data.data.lay_id })
                    this.saveProgress = false
                    this.getLayer()
                    //this.$router.push({ path: '/layers' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            }
        },
        getFileLayer(file){
            if( this.form.lay_name.length == 0 ){
                let nameTab = file.name.split(".")
                this.form.lay_name = nameTab[0]
            }
            this.form.lay_source_filename = file.name
        },
        switchFieldAssoc(){
            this.dialField = !this.dialField
        },
        addFieldAssoc(){
            if( this.form.lay_field === null || this.form.lay_field === undefined ){
                this.form.lay_field = []
            }
            this.form.lay_field.push( { source: '', destination: '', infoPanel: false, infoPopup: false } )
        },
        deleteFieldAssoc(id){
            this.form.lay_field.splice(id, 1);
        }
    },
    components: { }
}
</script>