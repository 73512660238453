<template>
<v-container fluid class="pa-0 ma-0">
    <v-toolbar class="pr-1 elevation-1" height="48px">
        <v-btn icon :href="btnBack"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche site</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <!-- Composant -->
    <v-card fluid class="ma-4 pa-3 elevation-1">
    <v-form ref="siteform" v-model="validFrom" lazy-validation>
        <v-layout wrap>
        <v-flex xs2 class="pa-2">
            <v-text-field label="CODDT" v-model="objTrackingSheet.trs_coddt" required></v-text-field>
        </v-flex>
        <v-flex xs10 class="pa-2">
            <v-text-field label="Nom du site" v-model="objTrackingSheet.trs_name" required :rules="formRules.name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select item-text="typ_name" item-value="typ_id" :items="cacheSitType" v-model="objTrackingSheet.trs_typ_id" label="Type" style="z-index: 2003;"></v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi :title="'Commune'" ressource="towns" 
                :listObject.sync="objTrackingSheet.trs_tow_id_link" :listParent.sync="autoCompleteSource.tow" params="order_by=tow_name"
                :fields="{ index: 'tow_id', text:'tow_name,tow_zip', resultlimit:false,  search:'tow_name,tow_zip', multiSelect:false, preprendIcon:'mdi-map-search' }">
            </auto-multi>dshqkj
        </v-flex>
        <v-flex xs12 class="pa-2">
            <auto-multi :title="'Lieu dit / adresse'" ressource="address" 
                :listObject.sync="objTrackingSheet.trs_adr_id" :listParent.sync="autoCompleteSource.adr" 
                :fields="{ index: 'adr_id', text:'adr_address', search:'adr_address', multiSelect:false, preprendIcon:'mdi-map-marker' }">
            </auto-multi>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <auto-multi :title="'Masse d\'eau'" ressource="watermass" 
                :listObject.sync="objTrackingSheet.trs_wam_id" :listParent.sync="autoCompleteSource.wam" 
                :fields="{ resultlimit:60, params: 'order_by=wam_name&order_direction=asc', index: 'wam_id', text:'wam_name', search:'wam_name', multiSelect:false, preprendIcon:'mdi-water' }">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Point kilométrique" v-model="objTrackingSheet.trs_mileage_point"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Distance à la ligne" v-model="objTrackingSheet.trs_rail_distance"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Code Mc2" v-model="objTrackingSheet.trs_mc2_code"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select item-text="sta_label" item-value="sta_id" :items="getCacheState('GEN')" v-model="objTrackingSheet.trs_sta_id" label="Statut" single-line ></v-select>
        </v-flex>
        </v-layout>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="success" :loading="saveProgress" @click.native="saveSite()" :disabled="!validFrom">
                <v-icon left dark>mdi-content-save</v-icon>
                <span class="hidden-sm-and-down">Enregistrer</span>
            </v-btn>
            <v-btn rounded color="primmary" href="/#/site/" disabled>Retour</v-btn>
        </v-card-actions>
    </v-form>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'
import { trsObj }       from '../mixins/trs_obj.js'

export default {
    name: 'site_edit',
    props: [],
    mixins: [ trsObj ],
    data: function () {
        return {
            cacheSitType: cacheGetters.getTypes('SIT') || [],
            cacheState: cacheGetters.getStates('GEN') || [],
            loading: { getVersion: false, getRessource: false },
            validFrom: false,
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
            dateSit: { start: false, end: false },
            objSite: { sit_id: 0, sit_sta_id: 'GEN01', sit_typ_id: 'SIT01', sit_name:'', sit_mc2_code: null, sit_mc2_id:null },
            formRules: {
                name: [
                    v => !!v || 'Le nom doit être renseigné',
                    v => (v && v.length <= 60) || 'Le nom doit être inférrieur à 60 characters'
                ]
            },
            autoComplete: {
                trs_tow_id: null, trs_adr_id: null, trs_wam_id: null
            },
            autoCompleteSource:{
                tow: [], adr: [], wam: []
            }
        }
    },
    computed: {
        btnBack : function(){
            if( this.sit_id > 0 && this.$route.params.mode === 'edit'){
                return '/#/trackingsheet/' + this.sit_id
            } else {
                return '/#/trackingsheet'
            }
        },
        sit_id : function(){
            return this.$route.params.trs_id
        }
    },
    mounted:function (){
        if( this.sit_id > 0 ){
            this.objTrackingSheet.trs_id = this.sit_id
            this.getTrackingSheetShow('town,watermass,address').then( () => {
                this.autoCompleteSource.tow.push(this.objTrackingSheet._info.town)
                this.autoCompleteSource.wam.push(this.objTrackingSheet._info.watermass)
                this.autoCompleteSource.adr.push(this.objTrackingSheet._info.address)
            })
        }
    },
    methods: {
        async saveSite(){
            if( this.objTrackingSheet.trs_id > 0 ){
                this.objTrackingSheet.trs_wam_id = (this.objTrackingSheet.trs_wam_id === undefined) ? null : this.objTrackingSheet.trs_wam_id
                this.objTrackingSheet.trs_adr_id = (this.objTrackingSheet.trs_adr_id === undefined) ? null : this.objTrackingSheet.trs_adr_id
                this.objTrackingSheet.trs_tow_id_link = (this.objTrackingSheet.trs_tow_id_link === undefined) ? null : this.objTrackingSheet.trs_tow_id_link
                await this.putStoreSite()
                await this.putTrackingSheetStore()
                this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistrée !' })
                this.$router.push({ path: '/trackingsheet/site/' + this.objTrackingSheet.trs_id + '/modifier' })
            } else {
                this.postStoreSite().then( () => {
                    this.postTrackingSheetStore().then( () => { 
                        this.$router.push({ path: '/trackingsheet/site/' + this.objTrackingSheet.trs_id + '/modifier' })
                    }).catch( () => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !' })
                    })
                }).catch( () => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !' })
                })
            }
        },
        postStoreSite(){
            return new Promise( (resolve, reject) => {
                this.objSite.sit_name                 = this.objTrackingSheet.trs_name
                this.objSite.sit_sta_id               = this.objTrackingSheet.trs_sta_id
                this.objSite.sit_typ_id               = this.objTrackingSheet.trs_typ_id
                this.$http.post( '/sites', this.objSite ).then( (response) => {
                    this.objSite.sit_id              = response.data.data.sit_id
                    this.objTrackingSheet.trs_sit_id = response.data.data.sit_id
                    resolve('resolved')
                }).catch( (error) => {
                    reject(error)
                })
            })
        },
        putStoreSite(){
            return new Promise( (resolve, reject) => {
                this.objSite.sit_name      = this.objTrackingSheet.trs_name
                this.objSite.sit_sta_id    = this.objTrackingSheet.trs_sta_id
                this.objSite.sit_typ_id    = this.objTrackingSheet.trs_typ_id
                this.objSite.sit_id        = this.objTrackingSheet.trs_sit_id
                this.$http.put( '/sites/' + this.objTrackingSheet.trs_sit_id, this.objSite ).then( () => {
                    resolve('resolved')
                }).catch( (error) => {
                    reject(error)
                })
            })
        },
        storeSite(){
            this.postStoreSite().then( () => {
                this.postTrackingSheetStore()
            }) 
        },
        getCacheState: cacheGetters.getStates,
    },
    components: { 
        'auto-multi': autoCompMulti,
    }
}
</script>

<style lang="scss">

</style>