var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[(_vm.documents.length < 2)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Nom"},model:{value:(_vm.objDocument.doc_name),callback:function ($$v) {_vm.$set(_vm.objDocument, "doc_name", $$v)},expression:"objDocument.doc_name"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"typ_name","item-value":"typ_id","label":"Type de fichier"},model:{value:(_vm.objDocument.doc_typ_id),callback:function ($$v) {_vm.$set(_vm.objDocument, "doc_typ_id", $$v)},expression:"objDocument.doc_typ_id"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-select',{attrs:{"items":_vm.table,"item-text":"table_name","item-value":"table_id","label":"Association","disabled":""},model:{value:(_vm.objDocument.doc_table_id),callback:function ($$v) {_vm.$set(_vm.objDocument, "doc_table_id", $$v)},expression:"objDocument.doc_table_id"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.objDocument.doc_description),callback:function ($$v) {_vm.$set(_vm.objDocument, "doc_description", $$v)},expression:"objDocument.doc_description"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-file-input',{ref:"documentfile",attrs:{"multiple":"","small-chips":"","show-size":"","label":"Fichier"},on:{"change":_vm.getFileDocument},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}],null,false,3534825248),model:{value:(_vm.objDocument.obj_file),callback:function ($$v) {_vm.$set(_vm.objDocument, "obj_file", $$v)},expression:"objDocument.obj_file"}})],1)],1):_vm._e(),(_vm.documents.length > 1)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-file-input',{ref:"documentfile",attrs:{"multiple":"","small-chips":"","show-size":"","label":"Fichier"},on:{"change":_vm.getFileDocument},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
var index = ref.index;
return [_c('v-chip',{attrs:{"small":"","label":"","close":"","color":"primary"},on:{"click:close":function($event){return _vm.removeChip(index)}}},[_vm._v(_vm._s(text))])]}}],null,false,1098506519),model:{value:(_vm.objDocument.obj_file),callback:function ($$v) {_vm.$set(_vm.objDocument, "obj_file", $$v)},expression:"objDocument.obj_file"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.documents,"item-key":"name","sort-by":"name"},scopedSlots:_vm._u([{key:"item.doc_name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Nom"},model:{value:(item.doc_name),callback:function ($$v) {_vm.$set(item, "doc_name", $$v)},expression:"item.doc_name"}})]}},{key:"item.doc_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.types,"item-text":"typ_name","item-value":"typ_id","label":"Type de fichier"},model:{value:(item.doc_typ_id),callback:function ($$v) {_vm.$set(item, "doc_typ_id", $$v)},expression:"item.doc_typ_id"}})]}},{key:"item.doc_table_id",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.table,"item-text":"table_name","item-value":"table_id","label":"Association","disabled":""},model:{value:(item.doc_table_id),callback:function ($$v) {_vm.$set(item, "doc_table_id", $$v)},expression:"item.doc_table_id"}})]}}],null,true)})],1):_vm._e()],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[(_vm.objDocument.eve_id > 0)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 pa-0",staticStyle:{"min-width":"5px"},attrs:{"outlined":"","color":"grey"}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,502240912),model:{value:(_vm.optionMenu),callback:function ($$v) {_vm.optionMenu=$$v},expression:"optionMenu"}},[_c('v-btn',{staticClass:"ma-0",attrs:{"color":"error","disabled":_vm.saveProgress},nativeOn:{"click":function($event){return _vm.deleteDocument()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can")]),_vm._v("Supprimer ")],1)],1):_vm._e(),_c('v-spacer'),(_vm.objDocument.doc_sta_id == 'GEN01')?_c('v-btn',{attrs:{"rounded":"","color":"success","loading":_vm.saveProgress,"disabled":_vm.saveProgress},nativeOn:{"click":function($event){return _vm.saveDocument()}}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.switchModal('modalDocument', 0)}}},[_vm._v(" Fermer ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }