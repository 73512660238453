/* *****************************
    Objet : Evenement
******************************** */
import { cacheGetters } from "../store/cache.module"

export const eveObj = {
    data: function () {
        return {
            objEvent: {
                eve_id: 0, eve_table_id: 'trs', eve_xxx_id  : '', eve_sta_id : 'GEN01',
                eve_typ_id_rec: 'REC01', eve_frequency_rec: 1, eve_eve_id: null,
                eve_typ_id: '', eve_code: '', eve_title: null, eve_description: '', 
                eve_date_start : null, eve_date_end : null, eve_value: 0, eve_cost_value: 0, 
                eve_delegation: 0, eve_resolve_percent: 0,
                eve_usr_id_own : '', eve_usr_id_creat: 0, eve_custom_fields: {}, eve_typ_id_parent: null
            },
            objEventClone: {},
            listEvent: [],
            metaEvent: {},
        }
    },
    mounted: function (){
        this.objEventClone = JSON.stringify( this.objEvent )
    },
    methods: {
        getEventFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        // Get Event
        getEventShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/' + options
                }
                this.$http.get( '/events/' + this.objEvent.eve_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objEvent[prop] !== undefined ){
                            this.objEvent[prop] = response.data.data[prop] 
                        }
                    }
                    this.objEvent.eve_date_end = ( this.objEvent.eve_date_end == '0000-00-00' ) ? null : this.objEvent.eve_date_end
                    this.objEvent.eve_date_start = ( this.objEvent.eve_date_start == '0000-00-00' ) ? null : this.objEvent.eve_date_start
                    this.objEvent.eve_custom_fields = (!this.objEvent.eve_custom_fields) ? {} : this.objEvent.eve_custom_fields
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getEventIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listTrackingSheet = []
                let searchCriteria = this.getEventFilter(page, perPage)
                searchCriteria = ''
                this.$http.get( '/events/' + searchCriteria + options ).then( (response) => {
                    this.metaEvent = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listEvent = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putEventUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/events/' + this.objEvent.eve_id, this.objEvent ).then( (response) => {
                    this.objEvent.eve_id = response.data.data.eve_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postEventStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/events', this.objEvent ).then( (response) => {
                    this.objEvent.eve_id = response.data.data.eve_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteObjEvent(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/events/' + this.objEvent.eve_id, this.objEvent ).then( () => {
                    this.objEvent.eve_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanObjEvent(){
            var EventClone = JSON.parse( this.objEventClone )
            var prop
            for( prop in EventClone ){
                if( this.objEvent[prop] !== undefined ){
                    this.objEvent[prop] = EventClone[prop]
                }
            }
        }
    }
}