<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Liste des couches SIG</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="modalHelp = !modalHelp" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-help-circle-outline</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Comment importer des couches SIG</span>
        </v-tooltip>
        <v-btn rounded to="/layers/ajouter" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Ajouter</span>
        </v-btn>
    </v-toolbar>
    <v-text-field append-icon="mdi-magnify" label="Recherche"
        solo single-line clearable hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:[`item.lay_sta_id`]="{ item }">
            <v-chip label outlined :color="( item.lay_sta_id == 'GEN01' ) ? 'green' : 'grey'">
                {{ (cacheState['GEN'][item.lay_sta_id]) ? cacheState['GEN'][item.lay_sta_id].sta_label : '-' }}
            </v-chip>
        </template>
        <template v-slot:[`item.lay_typ_id`]="{ item }">
            <v-chip label outlined color="blue">{{ (cacheType['SPR'][item.lay_typ_id]) ? cacheType['SPR'][item.lay_typ_id].typ_name : '-' }}</v-chip>
        </template>
        <template v-slot:[`item.lay_updated_at`]="{ item }">
        {{ locDateFormat.toLocale(item.lay_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}
        </template>
        <template v-slot:[`item.lay_color`]="{ item }">
            <v-btn small :color="item.lay_color"></v-btn>
        </template>
        <template v-slot:[`item.lay_id`]="{ item }">
            <v-btn color="grey" outlined :to="'/layers/' + item.lay_id">Modifier</v-btn>
            <v-btn class="ml-2" color="red" outlined @click="deleteItem(item.lay_id)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <template slot="no-data">
            <v-alert :value="true" color="info" class="mt-2" icon="mdi-information-outline">Aucune données</v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
    <v-dialog v-model="modalHelp" max-width="1000" persistent>
        <v-card>
            <v-toolbar dark color="primary" dense flat>
                <v-toolbar-title class="white--text">Importer une couche SIG</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="" outlined @click="modalHelp = !modalHelp"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="4" class="pa-2 pr-2 topline">
                        <v-img width="325" :src="tuto.zip"></v-img>
                    </v-col>
                    <v-col cols="8" class="pa-2 pr-2 topline">
                        <v-alert outlined type="success" prominent border="left">
                            Le fichier à importer doit être une archive ".zip" des éléments qui composent le shape.
                            (au moins les fichiers '.shp' et '.dbf')
                        </v-alert>
                    </v-col>
                    <v-col cols="4" class="pa-2 pr-2 topline">
                        <v-img width="325" :src="tuto.edit"></v-img>
                    </v-col>
                    <v-col cols="8" class="pa-2 pr-2 topline">
                        <v-alert outlined type="success" prominent border="left">
                            Lors de l'ajout de l'archive (cliquer sur "Fichier(s)") le programme rensigne automatiquement le nom de la couche avec le nom du fichier zip mais il est possible de le modifier.
                        </v-alert>
                    </v-col>
                    <v-col cols="4" class="pa-2 pr-2 topline">
                        
                    </v-col>
                    <v-col cols="8" class="pa-2 pr-2 topline">
                        <v-alert outlined type="success" prominent border="left">
                            - Etat Inactif : La couche n'est pas affichée par défaut à l'ouverture de la carte
                            <br />- Etat Actif : La couche est affichée par défaut à l'ouverture de la carte
                        </v-alert>
                    </v-col>
                    <v-col cols="4" class="pa-2 pr-2 topline">
                        <v-img width="325" :src="tuto.assoc"></v-img>
                    </v-col>
                    <v-col cols="8" class="pa-2 pr-2 topline">
                        <v-alert outlined type="success" prominent border="left">
                            Une fois le fichier enregistré, il est possible d'associer des champs du fichiers importé avec des données de la base compensea.
                            <br />- Cliquer sur le bouton "Associer des champs"
                            <br />- Cliquer le bouton "+"
                            <br />- Source: correspond au nom d'un champ du fichier shape importé
                            <br />- Association : correspond au nom du champ de la base COMPENSEA
                            <br />(i) Le programme va essayer de lire automatiquement des champs du fichier shape importé pour constituer une liste. Dans ce cas il suffit de selectionner le champ dans une liste.
                            <br />
                            <br />-> Code MC2 corespond au code MC2 en Enregistrer
                            <br />-> Ident MC fait l'association avec l'identifiant (4 première partie) exemple : MC-D37-CMAR0-VALVEUDE-00 = MC-D37-CMAR0-VALVEUDE-01
                            <br />-> Coddt correspond au Coddt
                            <br />-> le code commune INSEE permet d'associer avec la commune de la base COMPENSEA
                            <br />
                            <br />- Zone Info : permet d'afficher cette information au survol de la souris sur la carte
                            <br />- Popup info : permet d'afficher les informations du site COMPENSEA avec un lien vers celui ci
                        </v-alert>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'layers',
    props: [],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0 },
            headers: [
            { text: 'Nom',   align: 'left',   value: 'lay_name'},
            { text: 'Etat',     align: 'left',     value: 'lay_sta_id'},
            { text: 'Couleur', align: 'left', value: 'lay_color'},
            { text: 'Source',    align: 'left',    value: 'lay_typ_id'},
            { text: 'Modification',    align: 'left',   value: 'lay_updated_at'},
            { text: 'Action',    align: 'left',   value: 'lay_id'},
            ],
            items: [],
            locDateFormat: dateLocale, // Format de date
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            enableAdd: true,
            modalEvent : false,
            modalHelp: false,
            tuto : {
                zip: require(  '../assets/layer_zip.jpg'),
                edit: require( '../assets/layer_edit.jpg'),
                assoc: require('../assets/layer_assoc.jpg')
            }
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('layers')
        this.getLayers()
    },
    methods: {
        getLayers(){
            this.items = []
            var searchCriteria = '?page='+ this.pagination.page +'&per_page=false' // + this.pagination.itemsPerPage
            searchCriteria    += '&extended=true' //'&order_by=trs_name&order_direction=desc'
            this.$http.get( '/layers/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                //this.items = response.data.data
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        downloadSite(){
            this.$root.$toast({ color: 'success', text: 'Fonction non disponible !' })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getLayers()
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('layers', this.search)
        },
        deleteItem(id){
            this.$root.$confirm('Supprimer la couche (' + id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/layers/' + id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.getLayers()
                        this.switchModal('-', 0, true)
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        
    }
}
</script>

<style lang="css">

</style>